<script lang="ts">
import {defineComponent} from 'vue'
import VueCountdown from '@chenfengyuan/vue-countdown';
import PurchaseForm from "@/components/PurchaseForm.vue";

export default defineComponent({
  name: "PurchaseDECE",
  components : {PurchaseForm, VueCountdown},
  data()
  {
    return{
      platform : 'desktop',
      type : 'open' // open ,waiting
      , is_open : false,
    }
  },
  mounted() {
    const window_width = window.innerWidth;
    if (window_width < 650)
      this.platform = 'mobile'
  },
  methods: {
    transformSlotProps(props:any) {
      const formattedProps:any = {};
      for (let key in props)
      {
        const value = props[key];
        const x = value < 10 ? `0${value}` : String(value);

        formattedProps[key] = [x[0],x[1]];
      }


      return formattedProps;
    },
    changePhase()
    {
      if (this.type === 'open')
      {
        this.type = 'waiting';
        this.is_open = false;
      }
      else
      {
        this.type = 'open';
        this.is_open = false;
      }

    }
  },

})
</script>

<template>
  <div class="position-relative" id="PurchaseDECEPart">
    <div class="backLeftImg">
      <img src="@/assets/images/purchaseLeft.svg" alt="">
    </div>
    <div class="container position-relative">
      <div class="w-100 center d-block d-md-none">
        <img class="coin-img w-25" src="@/assets/images/dgt.webp" alt="">
      </div>
      <div class="row">
        <div class="col-12 col-md-7 align-self-center mb-3 connect-content">
<!--          <a class="small" @click="changePhase()">change phase</a>-->
          <div class="page-titles">Purchase DECE Token </div>

          <div class="page-contents mt-3">
            Be among the first people who get the most profit of DECE token
          </div>
          <div v-if="type === 'waiting'">
            <vue-countdown class="count-down-div" :time="3  * 60* 60 * 60 * 1000" v-slot="{ days, hours, minutes, seconds }" :transform="transformSlotProps">
              <div v-if="platform === 'desktop' || (platform === 'mobile' && (days[0] !== '0' || days[1] !== '0'))">
                <div class="time-items" >
                  <div class="item">{{days[0]}}</div>
                  <div class="item">{{days[1]}}</div>
                </div>
                <div>days</div>
              </div>
              <div>
                <div class="time-items">
                  <div class="item">{{hours[0]}}</div>
                  <div class="item">{{hours[1]}}</div>
                </div>
                <div>hours</div>
              </div>
              <div>
                <div class="time-items">
                  <div class="item">{{minutes[0]}}</div>
                  <div class="item">{{minutes[1]}}</div>
                </div>
                <div>minutes</div>
              </div>
              <div v-if="platform === 'desktop' || (platform === 'mobile' && (days[0] === '0' && days[1] === '0'))">
                <div class="time-items">
                  <div class="item">{{seconds[0]}}</div>
                  <div class="item">{{seconds[1]}}</div>
                </div>
                <div>seconds</div>
              </div>
            </vue-countdown>
          </div>
          <div v-if="type === 'open'">
<!--            <button v-if="!is_open" @click="is_open = !is_open" class="btn btn-gradient mt-4">Connect to wallet</button>-->
            <PurchaseForm></PurchaseForm>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-3 ms-auto align-self-center d-md-block d-none">
          <a class="page-titles" href="#howToBuy">Need Help?</a>
          <img class="coin-img w-100 p-2" src="@/assets/images/dgt.webp" alt="">
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="css">
a.page-titles{
  text-decoration: none;
  color:white;
}
.container
{
  position: relative;
  z-index: 1;
}
.count-down-div
{
  display: flex;
  text-align: center;
  grid-gap: 15px;
  margin: 2% 0;
}
.time-items
{
  display: flex;
  grid-gap: 5px;
}
.time-items .item
{
  border-radius: 4px;
  background: #2B1060;
  width: 54px;
  height: 100px;
  padding: 21px 18px 21px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vw;
}
.backLeftImg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-45%);
  z-index: 0;
  width: 23%;
}
.backLeftImg img
{
  width: 100%;
}
.mobile .time-items .item {
  font-size: 7vw;
}
.mobile .count-down-div {
  grid-gap: 12px;
  width: 100%;
  margin-top: 18px;
}
.mobile .time-items .item {
  width: 40px;
  height: 80px;
}
.mobile .backLeftImg[data-v-49e164e0] {
  left: 0;
  top: 23%;
  width: 52%;
}

</style>